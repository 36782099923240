<template>
  <vx-card title="Enjoy the all new features" class="mb-base">
    <div class="vx-row">
      <div class="vx-col md:w-2/5 w-full">
        <card :data="currentPlan" :disabled-button="true" />
      </div>
      <div class="vx-col md:w-3/5 w-full flex flex-col" v-if="order">
        <vx-card no-shadow class="w-full">
          <p class="font-semibold mb-3">Price Details</p>
          <div class="flex justify-between mb-5" v-for="(item, i) in order.lineItems" :key="i">
              <span class="text-grey">{{ item.description }}</span>
              <span>{{ item.formattedAmount }}</span>
          </div>

          <vs-divider class="mb-base mt-base" />

          <div class="flex justify-between font-semibold mb-3">
              <span>Amount paid</span>
              <span>{{ order.formattedToPay }}</span>
          </div>
        </vx-card>
        <vs-button :to="`/`" class="self-end mt-auto">Continue</vs-button>
      </div>
    </div>
  </vx-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Card from '@/views/pages/upgrade/components/Card.vue';
export default {
  components: {
    Card
  },
  data() {
    return { };
  },
  computed: {
    ...mapState('order', ['upgrade']),
    ...mapGetters('accounts', [
      'getPlanById'
    ]),
    currentPlan() {
      return this.getPlanById(this.$route.query.upgradeId);
    },
    order() {
      try {
        return this.upgrade.order;
      } catch (error) {
        return false;    
      }
    }
  },
  beforeCreate() {},
  mounted () {
    this.$store.dispatch('accounts/tenant');
  }
};
</script>